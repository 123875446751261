import { imgSrc } from '@/utils/helper'
import dayjs from '@/lib/dayjs'

export default {
  methods: {
    imgUrl (size, img) {
      return imgSrc(size, img)
    },
    dateFormat (date, format = null) {
      let formation = 'YYYY/MM/DD HH:mm'
      if (!date) return '無'
      if (format) formation = format
      return dayjs(date).format(formation)
    },
  },
}
